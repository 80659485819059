import {mapGetters, mapActions, mapMutations} from 'vuex';

import {isHasPermission} from '@/utils/utils';

export default {
  name: 'dashboard',
  data() {
    return {
      isGroupAdminOpen: false,
      isGroupSettingsOpen: false,
      isGroupStoreOpen: false,
      isGroupDeliveryOpen: false,
    }
  },
  components: {},
  created() {
    if (!this.deliveryList) {
      this.fetchDeliveryList();
    }
  },
  mounted() {},
  computed: {
    ...mapGetters({
      dashboardSidebarStatus: 'system/dashboardSidebarStatus',
      appSize: 'system/appSize',
      user: 'profile/user',
      deliveryList: 'delivery/deliveryList',
      isDeliveryListLoading: 'delivery/isDeliveryListLoading'
    }),
    isMobile() {
      return this.appSize.width <= 980;
    },
    isRouteAccessEnabled() {
      let flag = false;
      if (this.user && this.$route.meta && this.$route.meta.permission) {
        flag = isHasPermission(this.$route.meta.permission);
        if (!flag) {
          this.$toasted.error(this.$t('accessDenied'));
        }
      } else {
        flag = true
      }
      return flag
    }
  },
  watch: {
    dashboardSidebarStatus (val) {
      if (!val) {
        this.isGroupAdminOpen = false;
        this.isGroupSettingsOpen = false;
        this.isGroupStoreOpen = false;
        this.isGroupDeliveryOpen = false;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchDeliveryList: 'delivery/DELIVERY_REQUEST_LIST_SIMPLE_GET'
    }),
    ...mapMutations({
      setSidebarToggler: 'system/SYSTEM_DASHBOARD_SIDEBAR_TOGGLE',
    }),
    isCurrentRouteGroup(aliasPath, query) {
      if (query) {
        let queryKeys = Object.keys(query);
        return this.$route.path.includes(aliasPath) && this.$route.query &&
          queryKeys.every(item => {
            return query[item] === this.$route.query[item];
          });
      } else {
        return this.$route.path.includes(aliasPath);
      }
    },
    isLinkAccessEnabled(name) {
      return isHasPermission(name);
    },
    isGroupAccessEnabled(names) {
      return names.some(name => {
        return isHasPermission(name);
      })
    },
    closeSidebar(){
      this.setSidebarToggler(false);
    },
    toggleGroup(){
      if (!this.dashboardSidebarStatus) {
        this.setSidebarToggler(true);
      }
    },
  },
  destroyed() {},
}
